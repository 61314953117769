<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <custom-header id="report" :cols="customHeader" />
      <b-table
        id="report"
        class="edge mt-15"
        :fields="table.fields"
        :items="rows"
        :filter="filter"
        :sort-by="data.sort.by"
        :sort-desc="data.sort.desc"
        @sort-changed="$emit('sortChange', $event)"
        no-local-sorting
        responsive
      >
        <template slot="name" slot-scope="data">
          <expander
            :level="data.item.level"
            :has-items="hasItems(data.item)"
            :expanded="data.item._expanded"
            @expand="expand(data.item)"
          >
            <span slot="text" @click="expand(data.item)">
              <responsible
                v-if="data.item.employee"
                :responsible="data.item"
                :photo="data.item.photo"
                class="mr-5"
              ></responsible>
              {{ data.value }}
            </span>
          </expander>
        </template>

        <template v-for="period in periods" :slot="period + '_count'" slot-scope="data">
          <b-link :to="generateLink(data.item, period, 'count')" target="_blank">
            {{ data.value | formatNumber }}
          </b-link>
        </template>

        <template v-for="period in periods" :slot="period + '_worth'" slot-scope="data">
          {{ data.value | formatNumber }} &#8381;
        </template>

        <template v-for="period in periods" :slot="period + '_hours_fact'" slot-scope="data">
          <progress-bar :value="data.item[period + '_hours_mastering_percent'] || 0" :width="10" reverse>
            {{ data.value | formatNumber }}ч / {{ data.item[period + '_hours_plan'] | formatNumber('0,0.[0]') }}ч ({{
              data.item[period + '_hours_mastering_percent'] | formatNumber
            }}%)
          </progress-bar>
        </template>

        <template v-for="period in periods" :slot="period + '_hours_balance'" slot-scope="data">
          {{ data.value | formatDuration(undefined, undefined, 'hours') }}
        </template>
      </b-table>
    </template>
  </list>
</template>

<script>
  import qs from 'qs'
  import flatten from 'lodash/flatten'
  import { getUsersDropdown } from '../../../assets/js/common'

  import entityMixins from '../../../mixins/entity'
  import stairsMixins from '../../../mixins/stairs'

  import List from '../../../components/List'
  import Expander from '../../../components/Expander'
  import CustomHeader from '../../../components/TableHeader'
  import Responsible from '../../../components/Responsible'
  import ProgressBar from '../../../components/ProgressBar'

  export default {
    name: 'Report',
    components: {
      List,
      CustomHeader,
      ProgressBar,
      Expander,
      Responsible,
    },
    mixins: [entityMixins, stairsMixins],
    data() {
      return {
        rootKey: 'data',
        filters: [
          {
            name: 'date',
            type: 'date',
            range: true,
            legend: 'Период',
          },
          {
            label: 'Менеджеры',
            name: 'users',
            type: 'select',
            multiple: true,
            items: [],
            roles: ['manager'],
          },
          {
            label: 'Клиенты',
            name: 'clients',
            type: 'select',
            multiple: true,
            ajax: {
              entity: 'clients',
            },
          },
        ],
        periods: ['coming', 'now', 'completed'],
        table: {
          fields: [
            {
              key: 'name',
              label: 'Менеджеры',
            },
          ].concat(
            flatten(
              ['coming', 'now', 'completed'].map((period, index) => {
                let fields = [
                  {
                    key: `${period}_count`,
                    label: 'Кол-во',
                  },
                  {
                    key: `${period}_worth`,
                    label: 'Себестоимость',
                  },
                  {
                    key: `${period}_hours_fact`,
                    label: 'Расход часов',
                  },
                  {
                    key: `${period}_hours_balance`,
                    label: 'Остаток часов',
                  },
                ]

                fields.map(field => {
                  Object.assign(field, {
                    class: 'text-center',
                  })

                  return field
                })

                fields.unshift({
                  key: `${index}_delimiter`,
                  label: '&nbsp;',
                  class: 'delimiter mid-delimiter',
                })

                return fields
              })
            )
          ),
        },
      }
    },
    computed: {
      customHeader() {
        return [
          {
            label: 'Пришло в работу',
            slice: [3, 4],
          },
          {
            label: 'Сейчас в работе',
            slice: [8, 4],
          },
          {
            label: 'Завершено',
            slice: [13, 4],
          },
        ]
      },
    },
    methods: {
      generateLink(item, period, type) {
        let path = 'tasks',
          params = {}

        // Фильтр даты
        let date = this.module.state.filters.date

        if (date) {
          switch (period) {
            case 'coming':
              params.create_date = date
              break
            case 'completed':
              params.close_date = date
              break
          }
        }

        switch (period) {
          case 'now':
            params.preset = 'allOpened'
            break
          case 'completed':
            params.preset = 'allClosed'
            break
        }

        if (item.department) {
          params.responsible = 'department_' + item.id
        }

        if (item.employee) {
          params.responsible = 'user_' + item.id
        }

        return (
          `/${path}?` +
          qs.stringify({
            filters: {
              [path]: params,
            },
          })
        )
      },

      resultHandler(result) {
        let items = []

        result.data.forEach(item => {
          item.department = item.id !== 'all'
          item._rowVariant = 'bold'

          if (item.items) {
            item._expanded = true

            item.items = item.items.map(subItem => {
              subItem.employee = true
              return subItem
            })
          }

          items.push(item)
          items.push({ _rowVariant: 'delimiter mid-delimiter' })
        })

        result.items = items
        this.dataPrepare(result)
      },
    },
    beforeRouteEnter(to, from, next) {
      getUsersDropdown().then(items => {
        next(vm => {
          vm.filters.find(filter => {
            return filter.name === 'users'
          }).items = items
        })
      })
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .progress-bar {
      transition: unset !important;
    }

    .table-bold {
      font-weight: 600;
    }

    thead {
      text-align: center;
    }
  }
</style>
